<template>
  <div id="app">
    <Menu/>
    <main id="page-wrap"></main>
    <router-view />
  </div>
</template>

<script>

export default
{
  name: 'App',

}

</script>

<style>

</style>
